import { getUUID } from '../utilities/Storage';

const logEvent = (eventName, properties) => {
  const userId = getUUID();
  if (userId === null) {
    return;
  }
  const eventData = { ...properties, 'CT Source': 'WEB', source: 'WEB' };
  window.clevertap.event.push(eventName, eventData);
};

export const logButtonClick = (properties) => {
  logEvent('BUTTON_CLICK', properties || {});
};

export const logPageLoad = (properties) => {
  logEvent('PAGE_LOAD', properties || {});
};

export const logBannerImpression = (properties) => {
  logEvent('Banner_Impression', properties || {});
};

export const logBannerLeadCollection = (properties) => {
  logEvent('Banner_Lead_Collection', properties || {});
};

export const logBannerTap = (properties) => {
  logEvent('tap_banner', properties || {});
};

export const logBannerClicked = (properties) => {
  logEvent('LL_Banner_Clicked', properties || {});
};

export const logBrandBannerImpression = (properties) => {
  logEvent('Brand_Banner_Impression', properties || {});
};

export const logBrandBannerClicked = (properties) => {
  logEvent('Brand_Banner_Clicked', properties || {});
};

export const logCTEvent = (eventName, properties) => {
  logEvent(eventName, properties || {});
};

export const couponCodeCopied = (properties) => {
  logEvent('Coupon_Code_Copied', properties || {});
};

export const seeAllClicked = (properties) => {
  logEvent('See_All_Clicked', properties || {});
};

export const categoryClicked = (properties) => {
  logEvent('Category_Clicked', properties || {});
};

export const llAddedToCart = (properties) => {
  logEvent('LL_Added_To_Cart', properties || {});
};

export const llRemovedFromCart = (properties) => {
  logEvent('LL_Removed_From_Cart', properties || {});
};

export const seeAllFavouriteShopsClicked = (properties) => {
  logEvent('See_All_Favourite_Shops_Clicked', properties || {});
};

export const launchShopFront = (properties) => {
  logEvent('Launch_ShopFront', properties || {});
};

export const logCTCustomEvents = (
  eventName = '', properties = {},
) => {
  logEvent(eventName, properties);
};

export const orderSocialShareTapped = (properties) => {
  logEvent('Order_Social_Share_Tapped', properties || {});
};

export const orderDetailsViewed = (properties) => {
  logEvent('Order_Details_Viewed', properties || {});
};

export const notify = (properties) => {
  logEvent('Notify/Product Out Of Stock', properties || {});
};
